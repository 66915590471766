import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';

export namespace GeoJsonAPI {

    export class GetLayerGeoJson implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.data
        constructor(public layerId: number, public params: string[][] | object | null) {
            this.path = `/mapeditor/geojson/layer/${layerId}`;
        }
    }

}